'use client';

import { createContext, useContext, useRef } from 'react';
import { IntercomProvider } from 'react-use-intercom';
import {
  ChakraProvider,
  useDisclosure,
  UseDisclosureReturn,
} from '@chakra-ui/react';
import { SessionProvider } from 'next-auth/react';

import theme, { toastOptions } from '@raise/theme';

import Dialogs from './Dialogs';

interface DialogObject {
  waitlistDialog: UseDisclosureReturn;
  videoDialog: UseDisclosureReturn;
  sidebarDisclosure: UseDisclosureReturn;
  addNewButtonRef: React.RefObject<HTMLButtonElement>;
}

const RootContext = createContext({});
export const useRootContext = () =>
  useContext<Partial<DialogObject>>(RootContext);

export default function Providers({
  session,
  children,
}: {
  session: any;
  children: React.ReactNode;
}): JSX.Element {
  // Main layout context
  const waitlistDialog = useDisclosure();
  const videoDialog = useDisclosure();

  // App layout context
  const sidebarDisclosure = useDisclosure();
  const addNewButtonRef = useRef(null);

  return (
    <SessionProvider session={session}>
      <RootContext.Provider
        value={{
          waitlistDialog,
          videoDialog,
          sidebarDisclosure,
          addNewButtonRef,
        }}
      >
        <IntercomProvider
          appId={process.env.NEXT_PUBLIC_INTERCOM_APPID ?? ''}
          autoBoot
        >
          <ChakraProvider theme={theme} toastOptions={toastOptions}>
            {children}
            <Dialogs />
          </ChakraProvider>
        </IntercomProvider>
      </RootContext.Provider>
    </SessionProvider>
  );
}
